.plack {
    border-radius: .4rem;
    border-color: var(--color-blue-4);
}

.plack__title {
    color: var(--color-green-1);
}

.plack__description {
    font-weight: 400;
}
