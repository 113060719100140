.form__file {
    border: .2rem solid var(--color-gray-2);
    border-radius: .4rem;

    &::after {
        border-radius: 4.7rem;
        font-weight: bold;
        box-shadow: none;

        border: .2rem solid var(--color-blue);
        background: var(--color-white);
        color: var(--color-blue);
        padding: .8rem 2rem;

    }

    &:hover,
    &:focus {
        &::after {
            background: var(--color-blue);
            color: var(--color-white);
        }
    }
}
