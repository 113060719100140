.page-footer {
    background: var(--color-blue);
    color: var(--color-white);
}

.page-footer__container {
    &.-main {
        padding-bottom: 2rem;
    }

    &.-bottom {
        position: relative;
        z-index: 3;

        padding-top: 4rem;
        padding-bottom: 2rem;
    }
}

.page-footer__title {
    color: var(--color-white);
}

.page-footer__social-title {
    color: var(--color-white);
}

.page-footer__subtitle {
    color: var(--color-blue-3);
}

.page-footer__description {
    color: var(--color-green-2);
}

.page-footer__social-item {
    background: var(--color-green-2);
    color: var(--color-green-1);

    &:hover,
    &:focus {
        background: var(--color-white);
    }
}

.page-footer__block {
    > a:not(:last-child) img {
        margin-right: 1rem;
    }
}
