figcaption {
    position: relative;
    border: 0;
    color: var(--color-black);
    background: var(--color-white);

    &::before,
    &::after {
        content: '';
        position: absolute;
        z-index: 1;
        display: block;
        width: 7.2rem;
        height: 5.4rem;
    }

    &::before {
        top: -.9rem;
        left: -.9rem;
        border-top: .9rem solid var(--color-blue);
        border-left: .9rem solid var(--color-blue);
    }

    &::after {
        right: -.9rem;
        bottom: -.9rem;
        border-right: .9rem solid var(--color-blue);
        border-bottom: .9rem solid var(--color-blue);
    }
}
