.title-1 {
    color: var(--color-green-1);
}

.title-2 {
    color: var(--color-blue);
}

.title-3 {
    color: var(--color-blue);
}

h1 {
    @extend .title-1 !optional;
}

h2 {
    @extend .title-2 !optional;
}

h3 {
    @extend .title-3 !optional;
}

h4 {
    @extend .title-4 !optional;
}

h5 {
    @extend .title-5 !optional;
}

h6 {
    @extend .title-6 !optional;
}
