.nav-container {
    @media (--screen-mini-medium) {
        background: var(--color-blue-5);
        z-index: 9000;
    }

    @media (--screen-large) {
        align-items: flex-end;
    }
}
