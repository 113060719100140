ul:not([class]),
ol:not([class]),
.list {
    font-weight: 400;

    li {
        &::before {
            color: var(--color-blue-2);
        }
    }
}

ul:not([class]),
ul.list {
    li {
        &::before {
            content: '';
            box-sizing: border-box;
            display: inline-block;
            margin: 0 1rem 0 -2.5rem;
            width: 1rem;
            height: 1rem;
            border-radius: 100%;
            background: none;
            border: .2rem solid var(--color-blue-2)
        }
    }
}
