.wrapper {
    //&.-page-footer-bottom {
    //    position: relative;
    //    background: var(--color-blue-3);
    //
    //    &::before {
    //        content: '';
    //
    //        position: absolute;
    //        top: -.1rem;
    //        left: 0;
    //        z-index: 2;
    //
    //        display: block;
    //        width: 100%;
    //        height: 4rem;
    //        background: var(--color-blue);
    //        @supports (clip-path: polygon(0 0, 0 100%, 100% 100%)) {
    //            clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
    //        }
    //    }
    //
    //    &::after {
    //        content: '';
    //
    //        position: absolute;
    //        top: .1rem;
    //        left: 50%;
    //        z-index: 0;
    //        transform: translateX(-50%);
    //
    //        display: block;
    //        width: 50%;
    //        height: calc(100% - .1rem);
    //        background: rgba(255, 255, 255, .15);
    //        @supports (clip-path: polygon(0 0, 0 100%, 100% 100%)) {
    //            clip-path: polygon(20% 0%, 0% 100%, 100% 100%, 80% 0%);
    //        }
    //    }
    //}

    &.-shaped-bg-full-size,
    &.-shaped-bg {
        position: relative;
        background: var(--color-green-3);
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;

            display: block;
            width: 100%;
            height: 20rem;
            background: var(--color-white);

            @supports (clip-path: polygon(0 0, 0 100%, 100% 100%)) {
                clip-path: polygon(0 0, 0 0, 100% 100%, 100% 0);
            }
        }
    }

    &.-shaped-bg-inverted-full-size,
    &.-shaped-bg-inverted {
        position: relative;
        background: var(--color-white);
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;

            display: block;
            width: 100%;
            height: 25vw;
            background: var(--color-green-3);

            @supports (clip-path: polygon(0 0, 0 100%, 100% 100%)) {
                clip-path: polygon(0 0, 10% 60%, 80% 100%, 100% 0);
            }
        }
    }

    &.-shaped-bg-full-size,
    &.-shaped-bg-inverted-full-size {
        position: relative;
        background: transparent;

        &::before {
            content: '';
            position: absolute;
            top: auto;
            bottom: 0;
            left: 0;
            z-index: -1;

            display: block;
            width: 100%;
            height: 54rem;
            background: var(--color-green-3);

            @supports (clip-path: polygon(0 0, 0 100%, 100% 100%)) {
                clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 60%);
            }
        }
    }

    &.-shaped-bg-bottom-white-to-color {
        position: relative;
        background: transparent;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 0;

            display: block;
            width: 100%;
            height: 20rem;
            background: var(--color-green-3);

            @supports (clip-path: polygon(0 0, 0 100%, 100% 100%)) {
                clip-path: polygon(0 0, 0 0, 0% 100%, 100% 100%);
            }
        }
    }

    &.-shaped-bg-inverted-fixed-size {
        position: relative;
        background: var(--color-green-3);
        overflow: hidden;
        padding-top: 15rem;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;

            display: block;
            width: 100%;
            height: 15rem;
            background: var(--color-white);

            @supports (clip-path: polygon(0 0, 0 100%, 100% 100%)) {
                clip-path: polygon(0 0, 10% 60%, 80% 100%, 100% 0);
            }
        }
    }

    &.-colored {
        background: var(--color-green-3);
        overflow: hidden;
    }

    &.-padded-top {
        padding-top: 6rem;
    }
}
