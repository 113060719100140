@custom-media --screen-max-1024  (width < 1024px);
@custom-media --screen-min-1024  (width >= 1024px);
@custom-media --screen-small-max-1024 (width >= 420px) and (width < 1024px);
@custom-media --screen-medium-max-1024 (width >= 630px) and (width < 1024px);

.testimonial-reference-block {
    background-color: color-mod(var(--color-green-1) a(.1));

    @media (--screen-max-1024) {
        max-width: 100vw;
        overflow-x: hidden;
    }
}

.testimonial-reference-block__container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (--screen-max-1024) {
        max-width: 60rem;
    }

    @media (--screen-max-1024) {
        flex-wrap: wrap;
    }

    // IE11 bugfix
    @media all and (-ms-high-contrast:none) {
        justify-content: flex-start;
    }
}

.testimonial-reference-block__content {
    position: relative;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    min-width: 30%;
    background-color: var(--color-green-1);

    > * {
        position: relative;
        z-index: 1;
    }

    @media (--screen-max-1024) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    @media (--screen-min-1024) {
        max-width: 40rem;
        min-height: 39.7rem;
        margin-right: 11rem;
        padding-right: 4rem;
    }

    &::before {
        content: '';

        position: absolute;
        top: 0;
        left: -100vw;
        z-index: -1;
        display: block;
        width: 200vw;
        height: 100%;
        background-color: var(--color-green-1);

        @media (--screen-min-1024) {
            left: auto;
            right: 100%;
            width: calc(100vw - 100%);
        }
    }

    &::after {
        @media (--screen-min-1024) {
            content: '';
        }

        position: absolute;
        top: 0;
        right: -12rem;
        z-index: -1;

        display: block;
        width: 12rem;
        height: 100%;
        background-color: var(--color-green-1);
        @supports (clip-path: polygon(0 0, 0 100%, 100% 100%)) {
            clip-path: polygon(calc(0% + .1rem) 0%, calc(0% - .1rem) 100%, 100% 100%);
        }
        transform: translate3d(-.1rem, 0, 0);
    }
}

.testimonial-reference-block__extra-background {
    position: absolute;
    bottom: 0;
    left: calc(100% - 26.2rem);
    z-index: 0;

    display: block;
    width: 26.2rem;
    height: 16.4rem;
    background-image: url(bg.svg);
    background-position: left top;
    background-size: 26.2rem 20rem;
    background-repeat: no-repeat;

    @media (--screen-min-1024) {
        left: auto;
        right: -6rem;
    }
}

.testimonial-reference-block__title {
    display: block;
    margin-bottom: 2.4rem;
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 4rem;
    line-height: 1.2;
    color: var(--color-white);

    // IE11 bugfix
    @media all and (-ms-high-contrast:none) {
        margin-top: 10rem;
        max-width: 40rem;
    }
}

.testimonial-reference-block__blockquote {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    border-bottom: .4rem solid var(--color-green-1);

    width: 100%;
    margin: 4.4rem 0 4.4rem calc(15% - 2rem);
    padding: 4rem 2rem 4rem calc(10% + 2rem);
    min-height: 10rem;

    @media (--screen-medium-max-1024) {
        margin: 6.4rem 0 6.4rem calc(15% - 2rem);
        padding: 4rem 2rem 4rem calc(15% + 2rem);
    }

    @media (--screen-min-1024) {
        margin: 0;
        margin-left: auto;
        padding: 4rem 6rem 4rem 13.8rem;
        min-height: 24rem;
        max-width: 61rem;
    }

    &::before {
        background-color: var(--color-green-1);
    }

    &:hover,
    &:focus {
        .testimonial-reference-block__image {
            img {
                transform: scale(1.03);
            }

            &::before {
                transform: scale(1.03) rotate(10deg);
            }
        }
    }
}

.testimonial-reference-block__quote {
    margin-bottom: 2rem;

    &,
    > a {
        display: block;

        font-family: var(--font-default);
        font-weight: bold;
        font-size: 2rem;
        line-height: 1.3;
        color: var(--color-green-1);
        font-style: normal;
        text-decoration: none;

        @media (--screen-min-1024) {
            font-size: 2.5rem;
        }
    }

    > a {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}

.testimonial-reference-block__author-name {
    display: block;
    margin-bottom: 0;
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 2.2rem;
    line-height: 1.3;
    color: var(--color-blue);
    font-style: normal;
}

.testimonial-reference-block__author-title {
    display: block;
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 1.2;
    color: var(--color-blue-3);
    font-style: normal;
}

.testimonial-reference-block__image {
    transition: transform .3s ease;
    position: absolute;
    left: 0;
    transform: translateX(-50%);

    display: block;
    width: 100%;
    max-width: 20%;

    @media (--screen-min-1024) {
        max-width: 15.4rem;
        top: 50%;
        left: -7.7rem;
        transform: translateY(-50%);
    }

    img {
        transition: transform .3s ease;
        transform: scale(1);

        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 100%;
    }
}
