.item {
    background: none;

    &:hover {
        transform: translateY(-2rem);
    }
}

.item__image {
    margin-bottom: 2rem;

    img {
        transition: border-color .3s ease;
        border: .5rem solid var(--color-blue-1);
    }
}

.item__title {
    margin-bottom: 1.6rem;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 2rem;
    line-height: rem;
    color: var(--color-blue);
    text-transform: uppercase;
}

.item__description {
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.5;
    color: var(--color-blue-3);
}
