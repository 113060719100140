.downloads__title {
    color: var(--color-green-1);
}

.downloads__item {
    border-top: .1rem solid var(--color-green-1);

    &::before {
        width: 1.9rem;
        height: 2.3rem;
        color: var(--color-green-1);

        background-image: url('file.svg');
        background-size: 1.9rem 2.3rem;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url('file.svg');
            mask-size: 1.9rem 2.3rem;
            mask-repeat: no-repeat;
        }
    }

    &::after {
        background: var(--color-white);
    }
}

.downloads__name {
    color: var(--color-black);
}

.downloads__meta {
    color: var(--color-blue-3);
}
