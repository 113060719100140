.page-search {
    background: var(--color-blue-4);
}

.page-search__container {
    background: var(--color-white);
    border: .2rem solid var(--color-gray-3);
    border-radius: 2.4rem;
}

.page-search__close {
    &:hover,
    &:focus {
        color: var(--color-blue);
    }
}
