.nav {
    &.-language,
    &.-meta {
        @media (--screen-large) {
            padding-top: 1.4rem;
        }
    }

    &.-language {
        @media (--screen-large) {
            z-index: 1;
            margin-left: 0;
            padding-left: 4rem;
        }
    }
}

.nav__dropdown {
    @media (--screen-large) {
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem 3.4rem 1rem 1.4rem;
        top: 3rem;
        left: 2.4rem;
    }

    .nav.-language:hover &,
    .nav.-language:focus & {
        @media (--screen-large) {
            display: flex;
        }
    }
}

.nav__item {
    position: relative;
    z-index: 1;

    &::after {
        border-radius: .4rem;
    }

    &.-active,
    &:hover,
    &:focus {
        &::after {
            border-bottom-color: currentColor;
        }
    }

    .nav.-main & {
        font-weight: bold;

        @media (--screen-mini-medium) {
            &::after {
                border-bottom-width: .3rem;
            }
        }
    }

    .nav.-meta & {
        color: var(--color-blue);

        @media (--screen-mini-medium) {
            font-weight: 300;

            &::after {
                content: '';
                border-bottom-width: .2rem;
            }
        }
    }

    .nav.-language & {
        color: var(--color-blue-2);
        font-weight: 400;

        &.-active {
            &::before {
                background: var(--color-blue-3);
            }
        }
    }

    .nav.-page-footer & {
        margin-bottom: .5rem;
        font-weight: 400;
        color: var(--color-white);

        &::after {
            content: '';
            margin-top: .2rem;
            border-bottom-width: .2rem;
        }
    }
}
