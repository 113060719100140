.jumbotron {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 120rem;
    min-height: 52rem;
    margin-bottom: 4rem;

    @media screen and (min-width: 850px) and (max-width: 1024px) {
        margin-top: -7rem;
    }
}

.jumbotron__image {
    @media (--screen-mini-medium) {
        order: -1;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4rem;
    }

    @media (--screen-large) {
        position: absolute;
        top: 50%;
        right: 0;
        width: calc(50% - 4rem);
        max-width: 51rem;
        transform: translateY(-50%);
    }

    @media screen and (min-width: 1220px) {
        margin-right: 11rem;
    }

    img {
        position: relative;
        z-index: 2;
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;

        max-width: 58vw;
        margin: 0 auto;

        border-radius: 100%;
        box-shadow: 0 0 0 1rem rgba(var(--rgb-green-2), .4);

        @media (--screen-large) {
            max-width: initial;
        }
    }

    &::before {
        content: '';

        transition: transform .3s ease;
        box-sizing: border-box;

        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;

        transform: scale(1);
        background: transparent;

        border: 4.4rem solid var(--color-green-1);
        border-radius: 100%;

        display: block;
        margin: 0 auto;

        width: 50vw;
        height: 50vw;

        @media (--screen-large) {
            top: auto;
            bottom: -2rem;
            right: -4rem;
            width: 26.6rem;
            height: 26.6rem;
            max-width: initial;
        }
    }
}

.jumbotron__popup-trigger {
    z-index: 4;

    @media (--screen-mini-medium) {
        margin-top: -5rem;
    }

    @media (--screen-large) {
        position: absolute;
        bottom: 6.8rem;
        right: 0;
    }

    @media screen and (min-width: 1220px) {
        right: -11rem;
    }
}

.jumbotron__trigger-inner {
    position: relative;
    z-index: 2;

    display: block;
    margin: 0 auto;
    padding: 1.7rem 9rem 1.7rem 5rem;
    background: var(--color-green-2);
    color: var(--color-white);

    &:hover,
    &:focus {
        &::before {
            transform: rotate(5deg) translateY(-8rem);
        }
    }

    &::before {
        content: '';
        transition: transform .3s ease;

        position: absolute;
        top: 4.7rem;
        right: -2rem;
        bottom: -2rem;
        left: -2rem;
        z-index: 0;

        display: block;
        height: 100%;
        transform: rotate(5deg) translateY(0);
        @supports (clip-path: polygon(0 0, 0 100%, 100% 100%)) {
            background: var(--color-blue-7);
        }
    }

    &::after {
        $s: 2.5rem;

        @media (--screen-medium-large) {
            $s: 3.8rem;
        }

        content: '';

        position: absolute;
        right: 3rem;
        top: calc(50% - (#{$s} / 2));

        transition: background-color .3s ease;

        display: block;
        width: $s;
        height: $s;

        background-image: url(play.svg);
        background-size: $s $s;

        @supports (mask-repeat: no-repeat) {
            background: var(--color-green-1, var(--color-white));
            mask-image: url(play.svg);
            mask-repeat: no-repeat;
            mask-size: $s $s;
        }
    }
}

.jumbotron__title {
    color: var(--color-green-1);
}

.jumbotron__sub-title {
    color: var(--color-black-dark);
}

.jumbotron__trigger-title {
    position: relative;
    z-index: 1;
    display: block;
    margin-bottom: .5rem;
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1;
    color: var(--color-green-1);

    @media (--screen-mini-small) {
        font-size: 1.6rem;
    }
}

.jumbotron__trigger-sub-title {
    position: relative;
    z-index: 1;
    display: block;
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1;
    color: var(--color-black-dark);
    text-transform: uppercase;

    @media (--screen-mini-small) {
        font-size: 1.2rem;
    }
}

.jumbotron__button {
    @extend .button;
}

.jumbotron__description { // Because for some reason this is a rich text field I have to do this
    &,
    > p {
        font-size: 2.1rem;
        line-height: (32/21);
        font-weight: 400;
        color: var(--color-blue);
    }
}

.jumbotron__ornaments {
    position: absolute;
    bottom: 0;
    left: 3rem;
    z-index: 3;

    display: flex;
    flex-direction: column;

    > span {
        box-sizing: border-box;
        display: block;
        width: 2.9rem;
        height: 2.9rem;
        margin-bottom: 1.7rem;
        background: var(--color-green-1);
        border: .5rem solid var(--color-green-1);
        border-radius: 100%;

        &:nth-child(3) {
            background: none;
        }
    }
}
