.call-to-action-bar {
    background: var(--color-blue-3);

    &.-bright {
        background: var(--color-green-1);
    }
}

.call-to-action-bar__title {
    &,
    .call-to-action-bar.-bright & {
        color: var(--color-white);
    }
}

.call-to-action-bar__description {
    &,
    .call-to-action-bar.-bright & {
        color: var(--color-white);
    }
}
