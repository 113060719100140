.pagination__item {
    color: var(--color-gray_50);
    background: none;

    a {
        &:hover,
        &:focus {
            color: var(--color-black);
        }
    }

    &.-current {
        border: .2rem solid var(--color-green-1);
        color: var(--color-black);
        background: none;
        border-radius: 100%;
    }
}
