.nav-wrapper {
    position: relative;
    margin-bottom: 1.4rem;

    &::after {
        @media (--screen-large) {
            content: '';
        }

        position: absolute;
        top: -.1rem;
        right: -2rem;
        z-index: 0;

        display: block;
        width: 100%;
        height: calc(100% + .1rem);

        background: var(--color-blue);

        @supports (clip-path: polygon(0 0, 0 100%, 100% 100%)) {
            background: var(--color-blue-7);
            clip-path: polygon(50% 0%, 10% 100%, 100% 100%, 100% 0%);
        }
    }
}
