/*De Witte Mol Theme*/
@import 'config.scss';

@function toRGBstring($hexColor) {
    @return "rgb(#{red($hexColor)},#{green($hexColor)},#{blue($hexColor)})";
}

@function toRGB($hexColor) {
    @return "#{red($hexColor)},#{green($hexColor)},#{blue($hexColor)}";
}

$colors: (
    blue-1: #1E6DBD,
    blue-2: #5A89B5,
    blue-3: #92AACB,
    blue-4: #E9EEF5,
    blue-5: #E9EEF5,
    gray-1: #E9EEF5,
    gray-2: #EDFFF0, // -page-footer-tel
    gray-3: #D0DBE5, // -page-search border
    green-1: #0D761F,
    green-2: #C6F5CE,
    green-3: #EBF5ED,
    black-dark: #000A12,
);

:root {
    @each $name, $hex in $colors {
        --color-#{$name}: #{toRGBstring($hex)};
        --rgb-#{$name}: #{toRGB($hex)};
    }

    // Default overwrites
    --color-blue: var(--color-blue-1);
    --color-black: var(--color-black-dark);
    --font-default: 'Muli', Helvetica, Arial, sans-serif;
}

@import '../themes/dewittemol/components/**/[!_]*.scss';
@import '../components/Front/_helpers.scss'; // helpers last to overwrite previous styling
