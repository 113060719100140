.image-card {
    background: var(--color-blue-5);

    &.-light {
        background: var(--color-white);
    }

    &:hover,
    &:focus {
        .image-card__title {
            &,
            a {
                color: var(--color-black);
            }
        }
    }
}

.image-card__meta-item {
    color: var(--color-green-1);
}

.image-card__location {
    &:hover,
    &:focus {
        text-decoration: none;
    }
}
