
.section-heading__back-link {
    &:hover,
    &:focus {
        color: var(--color-blue-2);
    }
}

.section-heading__title {
    .section-heading.-highlighted & {
        color: var(--color-green-1);
    }
}

.section-heading__description {
    color: var(--color-black-dark);

    .section-heading.-small & {
        color: var(--color-black-dark);
    }

    .section-heading.-highlighted & {
        color: var(--color-black);
    }

    .section-heading.-light-font & {
        font-weight: 300;
    }
}

.section-heading__meta-item {
    color: var(--color-blue-3);
}
