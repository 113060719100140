.main-image {
    position: relative;
    margin-bottom: 8rem;
    max-width: 100vw;

    @media (--screen-medium-large) {
        margin-bottom: 10.5rem;
    }

    @media (--screen-large) {
        max-width: 90rem;
    }

    img {
        position: relative;
        z-index: 2;
        padding-top: 1.4rem;
    }
}

.main-image__backgrounds {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

    display: block;
    width: 100%;
    height: 100%;

    &::before,
    &::after {
        content: '';

        position: absolute;
        z-index: 5;

        display: block;
        width: 22.6rem;
        height: 17.2rem;
    }

    &::before {
        top: 0;
        left: -1.4rem;
        border-left: 2.8rem solid var(--color-blue);
        border-top: 2.8rem solid var(--color-blue);
    }

    &::after {
        right: -1.4rem;
        bottom: -1.4rem;
        border-right: 2.8rem solid var(--color-blue);
        border-bottom: 2.8rem solid var(--color-blue);
    }
}
