article blockquote,
.blockquote {
    background-color: var(--color-green-3);
    border-bottom: .4rem solid var(--color-green-1);

    &::before {
        background-color: var(--color-green-1);
    }

    p {
        color: var(--color-green-1);
    }

    footer {
        color: var(--color-green-1);
    }
}
