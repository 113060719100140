.overlay-card {
    position: relative;
    display: block;
    width: 100%;
    max-width: 60rem;
    min-height: 60rem;
    margin-left: 4rem;
    margin-bottom: 4rem;

    @media screen and (max-width: 1100px) {
        max-width: 50rem;
        min-height: 50rem;
    }
}

.overlay-card__ornaments {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    z-index: 3;

    display: flex;
    flex-direction: row;

    > span {
        box-sizing: border-box;
        display: block;
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 2.3rem;
        background: var(--color-blue-1);
        border: .3rem solid var(--color-blue-1);
        border-radius: 100%;

        &:nth-child(3) {
            background: none;
        }
    }
}

.overlay-card__image {
    position: relative;
    z-index: 0;

    display: block;
    width: 100%;
    height: 100%;

    @media (--screen-mini-medium) {
        height: 0;
        padding-bottom: 100%;
        margin-bottom: 4rem;
    }

    @media (--screen-large) {
        position: absolute;
        top: 0;
        left: 0;
    }

    &::after {
        content: '';

        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: linear-gradient(
                to bottom,
                rgba(0,0,0,0) 0%,
                rgba(0,0,0,1) 100%
        );

        border-radius: 100%;
    }

    img {
        position: relative;
        z-index: 0;

        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;

        @media (--screen-mini-medium) {
            position: absolute;
        }
    }
}

.overlay-card__content {
    position: relative;
    z-index: 3;
    left: 0;
    padding-left: 3rem;

    .overlay-card.-flipped & {
        left: 0;
    }

    @media (--screen-large) {
        position: absolute;
        left: 6.6rem;
        bottom: 8.8rem;
        padding-left: 0;

        .overlay-card.-flipped & {
            left: 6.6rem;
        }
    }
}

.overlay-card__meta {
    display: block;
    margin-bottom: .2rem;
    padding-left: 0;
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.26;
    color: var(--color-blue-3);
    text-transform: uppercase;

    @media (--screen-large) {
        color: var(--color-white);
    }
}

.overlay-card__title {
    display: block;
    margin-bottom: 2rem;
    padding-left: 0;
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 2rem;
    line-height: 1.26;
    color: var(--color-green-1);

    @media (--screen-medium-large) {
        font-size: 3.8rem;
    }

    @media (--screen-large) {
        color: var(--color-green-2);
    }
}

.overlay-card__description {
    display: block;
    margin-bottom: 2rem;
    padding-left: 0;
    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.46;
    color: var(--color-blue-3);

    @media (--screen-large) {
        color: var(--color-white);
    }
}

.overlay-card__location {
    position: relative;
    display: block;
    margin: 0 1rem 3rem 0;
    padding-left: -2rem;

    font-family: var(--font-default);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.26;
    color: var(--color-blue-3);
    text-decoration: none;

    @media (--screen-large) {
        color: var(--color-white);
    }

    &::before {
        content: '';

        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        transition: background .3s ease;
        display: block;
        width: 2.2rem;
        height: 2.2rem;

        background-image: url(circled-pin.svg);
        background-size: 2.2rem 2.2rem;

        @supports (mask-repeat: no-repeat) {
            background: currentColor;
            mask-image: url(circled-pin.svg);
            mask-size: 2.2rem 2.2rem;
            mask-repeat: no-repeat;
        }
    }
}
