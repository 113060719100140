a,
.link {
    &.-arrowed {
        color: var(--color-blue-3);
    }

    &.-page-footer-tel {
        color: var(--color-gray-2);
    }

    &.-green-2 {
        color: var(--color-green-2);
    }

    &.-page-footer-bottom {
        color: var(--color-white);
    }
}
