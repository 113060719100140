.logo {
    position: relative;
    margin-top: 2rem;
    max-width: 12rem;

    img {
        position: relative;
        z-index: 1;
    }


    @media (--screen-medium-large) {
        margin-top: 3rem;
    }

    @media (--screen-large) {
        margin-right: 2rem;
    }
}
