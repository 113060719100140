.testimonial {
    margin-bottom: 9rem;
    margin-left: 4rem;

    &:hover,
    &:focus {
        .testimonial__image {
            img {
                transform: scale(1.03);
            }

            &::before {
                transform: scale(1.03) rotate(10deg);
            }
        }
    }
}

.testimonial__image {
    max-width: 23.8rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.2rem;

    &::before {
        $w: 4.8rem;
        $h: 3.6rem;
        content: '';

        position: absolute;
        top: -2.5rem;
        right: 2rem;
        z-index: 2;

        display: inline-block;
        width: $w;
        height: $h;

        background-image: url(quotes.svg);
        background-size: $w $h;

        @supports (mask-repeat: no-repeat) {
            background: var(--color-green-1);
            mask-image: url(quotes.svg);
            mask-size: $w $h;
            mask-repeat: no-repeat;
        }
    }

    img {
        transition: transform .3s ease;
        transform: scale(1);
        object-fit: cover;
        border-radius: 100%;
        box-shadow: 0 0 0 1rem rgba(var(--rgb-green-2), .4);
    }
}

.testimonial__ornaments {
    position: absolute;
    right: -2rem;
    bottom: 2.5rem;
    z-index: 3;

    display: flex;
    flex-direction: row;

    > span {
        box-sizing: border-box;
        display: block;
        width: 1.8rem;
        height: 1.8rem;
        margin-left: 1.1rem;
        background: var(--color-green-1);
        border: .3rem solid var(--color-green-1);
        border-radius: 100%;

        &:nth-child(3) {
            background: none;
        }
    }
}

.testimonial__title {
    color: var(--color-green-1);
}

.testimonial__subtitle {
    color: var(--color-blue-3);
}

