.form__field {
    border: .2rem solid var(--color-gray-3);
    border-radius: .4rem;

    &.-page-search {
        border-radius: 2.4rem;
        padding: .8rem 1.5rem .8rem 4rem;
    }

    &.-read-only {
        border: 0;
        margin: 0;
        padding: 0;
        text-align: center;

        font-family: var(--font-default);
        font-weight: bold;
        font-size: 2.2rem;
        line-height: 1.3;
        color: var(--color-blue);
    }

    &.-has-value,
    &:not(:empty):not(select) {
        background-color: var(--color-gray-3);
    }

    &:focus {
        border-color: var(--color-blue-2)
    }

    &::placeholder {
        font-family: var(--font-default);
        font-weight: bold;
        font-size: 1.6rem;
        color: var(--color-blue-2);
    }
}
