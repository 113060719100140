.button {
    border-radius: 4.7rem;
    font-weight: bold;
    box-shadow: .1rem .3rem .7rem 0 rgba(0, 0, 0, .3);

    background-color: var(--color-green-1);
    color: var(--color-white);

    &:hover,
    &:focus {
        background: var(--color-white);
        color: var(--color-green-1);
    }

    &:active {
        outline: none;
        box-shadow: 0 .1rem .3rem 0 rgba(0, 0, 0, .7);
    }

    &.-spaced-out {
        margin: 0 1rem 1rem 1rem;
    }

    &.-outlined {
        border: .2rem solid var(--color-blue);
        background: var(--color-white);
        color: var(--color-blue);

        &:hover,
        &:focus {
            background: var(--color-blue);
            color: var(--color-white);
        }
    }

    &.-page-search,
    &.-blue {
        background-color: var(--color-blue);
        border-color: var(--color-blue);
        color: var(--color-white);

        &:hover,
        &:focus {
            background: var(--color-white);
            color: var(--color-blue);
        }
    }

    &.-page-search {
        box-shadow: none;
    }

    &.-call-to-action-bar,
    &.-white {
        box-sizing: border-box;
        border: .2rem solid var(--color-white);
        background-color: var(--color-white);
        color: var(--color-blue);

        &:hover,
        &:focus {
            background-color: var(--color-blue);
            border-color: var(--color-blue);
            color: var(--color-white);
        }
    }

    &.-call-to-action-bar {
        padding-left: 6rem;
        padding-right: 6rem;
    }

    .image-card & {
        &:hover &,
        &:focus & {
            background: var(--color-white);
            color: var(--color-blue);
            box-shadow: .1rem .3rem .7rem 0 rgba(0, 0, 0, .3);
        }

        &:active & {
            background: var(--color-white);
            color: var(--color-blue);
            box-shadow: 0 .1rem .3rem 0 rgba(0, 0, 0, .7);
        }
    }

    &.-outlined,
    .article & {
        //border: .2rem solid var(--color-blue);
        //background: var(--color-white);
        //color: var(--color-blue);

        //&:hover,
        //&:focus {
        //    background: var(--color-blue);
        //    color: var(--color-white);
        //}
    }

    .article & {
        margin-bottom: 4rem;
        margin-right: .5rem;
    }

    .inline-centered-call-to-action & {
        margin-bottom: 4rem;
        margin-right: .5rem;
        padding: 1.6rem 2.8rem;
        color: var(--color-white);
        background: var(--color-blue);

        &:hover,
        &:focus {
            background: var(--color-white);
            color: var(--color-blue);
        }
    }

    .image-card.-has-state &,
    .image-card.-has-calendar & {
        background: var(--color-green-1);
        color: var(--color-white);
    }

    .image-card.-has-state:hover &,
    .image-card.-has-state:focus &,
    .image-card.-has-calendar:hover &,
    .image-card.-has-calendar:focus & {
        background: var(--color-blue);
        color: var(--color-white);
    }
}

/*Due to the inability of the ck-editor to place .button classes this piece of css needs to be here */
.inline-centered-call-to-action,
.inline-call-to-action {
    a {
        @extend .button;
        color: var(--color-white);
        text-decoration: none;
    }
}

/* Button Converter converts any direct link into a button, without the link having the button class */
/* Due to component scoping this class is placed in here */
.button-converter {
    > a:not([class]) {
        @extend .button;
    }
}
