.image-gallery__item {
    margin-bottom: 2rem;
    padding-bottom: 0;

    &::before {
        margin-top: 0;
    }

    &::after {
        box-sizing: border-box;
        border: .4rem solid var(--color-green-1);
        background: color-mod(var(--color-green-1) a(70%));
        height: 100%;
    }

    &.-count-box {
        border: .3rem solid var(--color-green-1);
    }

    img {
        box-shadow: none;
    }

    &:hover,
    &:focus {
        &::after {
            opacity: 1;
        }
    }
}
