@font-face {
    font-family: 'Muli';
    src: url('fontfiles/Muli/Muli-BlackItalic.eot');
    src: url('fontfiles/Muli/Muli-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('fontfiles/Muli/Muli-BlackItalic.woff2') format('woff2'),
    url('fontfiles/Muli/Muli-BlackItalic.woff') format('woff'),
    url('fontfiles/Muli/Muli-BlackItalic.ttf') format('truetype'),
    url('fontfiles/Muli/Muli-BlackItalic.svg#Muli-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Muli';
    src: url('fontfiles/Muli/Muli-ExtraBoldItalic.eot');
    src: url('fontfiles/Muli/Muli-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('fontfiles/Muli/Muli-ExtraBoldItalic.woff2') format('woff2'),
    url('fontfiles/Muli/Muli-ExtraBoldItalic.woff') format('woff'),
    url('fontfiles/Muli/Muli-ExtraBoldItalic.ttf') format('truetype'),
    url('fontfiles/Muli/Muli-ExtraBoldItalic.svg#Muli-ExtraBoldItalic') format('svg');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Muli';
    src: url('fontfiles/Muli/Muli-Italic.eot');
    src: url('fontfiles/Muli/Muli-Italic.eot?#iefix') format('embedded-opentype'),
    url('fontfiles/Muli/Muli-Italic.woff2') format('woff2'),
    url('fontfiles/Muli/Muli-Italic.woff') format('woff'),
    url('fontfiles/Muli/Muli-Italic.ttf') format('truetype'),
    url('fontfiles/Muli/Muli-Italic.svg#Muli-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Muli';
    src: url('fontfiles/Muli/Muli-SemiBold.eot');
    src: url('fontfiles/Muli/Muli-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('fontfiles/Muli/Muli-SemiBold.woff2') format('woff2'),
    url('fontfiles/Muli/Muli-SemiBold.woff') format('woff'),
    url('fontfiles/Muli/Muli-SemiBold.ttf') format('truetype'),
    url('fontfiles/Muli/Muli-SemiBold.svg#Muli-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Muli';
    src: url('fontfiles/Muli/Muli-SemiBoldItalic.eot');
    src: url('fontfiles/Muli/Muli-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('fontfiles/Muli/Muli-SemiBoldItalic.woff2') format('woff2'),
    url('fontfiles/Muli/Muli-SemiBoldItalic.woff') format('woff'),
    url('fontfiles/Muli/Muli-SemiBoldItalic.ttf') format('truetype'),
    url('fontfiles/Muli/Muli-SemiBoldItalic.svg#Muli-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Muli';
    src: url('fontfiles/Muli/Muli-Regular.eot');
    src: url('fontfiles/Muli/Muli-Regular.eot?#iefix') format('embedded-opentype'),
    url('fontfiles/Muli/Muli-Regular.woff2') format('woff2'),
    url('fontfiles/Muli/Muli-Regular.woff') format('woff'),
    url('fontfiles/Muli/Muli-Regular.ttf') format('truetype'),
    url('fontfiles/Muli/Muli-Regular.svg#Muli-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Muli';
    src: url('fontfiles/Muli/Muli-LightItalic.eot');
    src: url('fontfiles/Muli/Muli-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('fontfiles/Muli/Muli-LightItalic.woff2') format('woff2'),
    url('fontfiles/Muli/Muli-LightItalic.woff') format('woff'),
    url('fontfiles/Muli/Muli-LightItalic.ttf') format('truetype'),
    url('fontfiles/Muli/Muli-LightItalic.svg#Muli-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Muli';
    src: url('fontfiles/Muli/Muli-Light.eot');
    src: url('fontfiles/Muli/Muli-Light.eot?#iefix') format('embedded-opentype'),
    url('fontfiles/Muli/Muli-Light.woff2') format('woff2'),
    url('fontfiles/Muli/Muli-Light.woff') format('woff'),
    url('fontfiles/Muli/Muli-Light.ttf') format('truetype'),
    url('fontfiles/Muli/Muli-Light.svg#Muli-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Muli';
    src: url('fontfiles/Muli/Muli-ExtraLight.eot');
    src: url('fontfiles/Muli/Muli-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('fontfiles/Muli/Muli-ExtraLight.woff2') format('woff2'),
    url('fontfiles/Muli/Muli-ExtraLight.woff') format('woff'),
    url('fontfiles/Muli/Muli-ExtraLight.ttf') format('truetype'),
    url('fontfiles/Muli/Muli-ExtraLight.svg#Muli-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Muli';
    src: url('fontfiles/Muli/Muli-Black.eot');
    src: url('fontfiles/Muli/Muli-Black.eot?#iefix') format('embedded-opentype'),
    url('fontfiles/Muli/Muli-Black.woff2') format('woff2'),
    url('fontfiles/Muli/Muli-Black.woff') format('woff'),
    url('fontfiles/Muli/Muli-Black.ttf') format('truetype'),
    url('fontfiles/Muli/Muli-Black.svg#Muli-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Muli';
    src: url('fontfiles/Muli/Muli-Bold.eot');
    src: url('fontfiles/Muli/Muli-Bold.eot?#iefix') format('embedded-opentype'),
    url('fontfiles/Muli/Muli-Bold.woff2') format('woff2'),
    url('fontfiles/Muli/Muli-Bold.woff') format('woff'),
    url('fontfiles/Muli/Muli-Bold.ttf') format('truetype'),
    url('fontfiles/Muli/Muli-Bold.svg#Muli-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Muli';
    src: url('fontfiles/Muli/Muli-ExtraBold.eot');
    src: url('fontfiles/Muli/Muli-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('fontfiles/Muli/Muli-ExtraBold.woff2') format('woff2'),
    url('fontfiles/Muli/Muli-ExtraBold.woff') format('woff'),
    url('fontfiles/Muli/Muli-ExtraBold.ttf') format('truetype'),
    url('fontfiles/Muli/Muli-ExtraBold.svg#Muli-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Muli';
    src: url('fontfiles/Muli/Muli-BoldItalic.eot');
    src: url('fontfiles/Muli/Muli-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('fontfiles/Muli/Muli-BoldItalic.woff2') format('woff2'),
    url('fontfiles/Muli/Muli-BoldItalic.woff') format('woff'),
    url('fontfiles/Muli/Muli-BoldItalic.ttf') format('truetype'),
    url('fontfiles/Muli/Muli-BoldItalic.svg#Muli-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Muli';
    src: url('fontfiles/Muli/Muli-ExtraLightItalic.eot');
    src: url('fontfiles/Muli/Muli-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
    url('fontfiles/Muli/Muli-ExtraLightItalic.woff2') format('woff2'),
    url('fontfiles/Muli/Muli-ExtraLightItalic.woff') format('woff'),
    url('fontfiles/Muli/Muli-ExtraLightItalic.ttf') format('truetype'),
    url('fontfiles/Muli/Muli-ExtraLightItalic.svg#Muli-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
}

